<template>
  <div>
    <a-modal v-model="visibleModalQuestionCard" :closable="false" :maskClosable="false" width="90%" :zIndex="800"
      destroyOnClose>
      <template slot="footer">
        <a-button class="d-none" key="back"> Close </a-button>
        <a-button class="d-none" key="submit" type="primary"> Submit </a-button>
      </template>
      <EditSoalCbt :listSoal="excelData" :isAttendanceSubmitted="false" @save-edit="saveEdit" @cancel-edit="cancelEdit"
        :useCard="false" :isCbt="true" />
    </a-modal>
    <a-modal v-model="visibleModalQuestionBank" width="90%" :zIndex="800" destroyOnClose title="Choose Question Bank">
      <template slot="footer">
        <a-button class="d-none" key="back"> Close </a-button>
        <a-button class="d-none" key="submit" type="primary"> Submit </a-button>
      </template>
      <QuestionBankList @chooseQuestionBank="chooseQuestionBank" :subjects="mapels" />
    </a-modal>
    <template>
      <a-row :gutter="32">
        <a-col :sm="24" :lg="14">
          <div class="card-full">
            <div class="detail">
              <div class="cui__utils__heading mb-0">
                <strong>QUESTIONS PREVIEW</strong>
              </div>
              <div class="text-muted">You can see Questions here</div>

              <a-button v-if="excelData.length" @click.prevent="handleToggleModalQuestionCard"
                :loading="loadingEditQuestion" type="primary" class="mt-3">
                <a-icon v-if="!loadingEditQuestion" type="edit" />Edit Questions
                Card
              </a-button>
              <hr />
              <template v-if="!excelData.length">
                <LoadingState v-if="isLoadingQuestions" />
                <a-result v-else status="404" title="It's Empty" sub-title="You have to add question">
                  <template #extra>
                    <div>
                      <a-button id="create-cbt-question" @click.prevent="handleToggleModalQuestionCard" type="primary"
                        size="large" class="mt-3 mr-3">
                        <a-icon type="plus" />Create Question Card
                      </a-button>
                      <a-button id="create-cbt-question" @click.prevent="handleToggleModalQuestionBank" size="large"
                        class="mt-3">
                        <a-icon type="import" />Import from Question Bank
                      </a-button>
                    </div>
                  </template>
                </a-result>
                <!-- <EmptyKartuSoal /> -->
                <!-- <KartuSoalGuide /> -->
              </template>
              <template v-if="excelData.length && !editable">
                <CounterCbt :counterCBT="counter" />
                <ListSoalCbt :key="reloadCount" :listSoal="excelData" />
              </template>
            </div>
          </div>
        </a-col>
        <a-col :sm="24" :lg="10">
          <!-- <Card>
            <CbtForm
              :dataCBT="dataCBT"
              :selectedPengawas="selectedPengawas"
              :listMapels="mapels"
              :listLevels="levels"
              :listTypes="types"
              :listPengawas="pengawas"
              :isFilledSoal="isFillSoal"
              @save-cbt="generateCBT"
            />
          </Card>-->
          <div class="card-full">
            <div class="detail">
              <div class="cui__utils__heading mb-0">
                <strong>GENERATE CBT</strong>
              </div>
              <div class="text-muted mb-3">You can generate CBT here</div>
              <!-- {{ isDisabledGenerate }} -->
              <hr />
              <a-row :gutter="16">
                <a-col :span="24">
                  <a-form-item label="Subject" required :colon="false">
                    <a-select size="large" style="width: 100%; height: 40px" v-model="dataCBT.id_mata_pelajaran"
                      @change="handleSelectMapel">
                      <a-select-option v-for="item in mapels" :key="item.id" :value="item.id">{{ item.nama
                      }}</a-select-option>
                      <a-select-option value="16">PPDB</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Type" required :colon="false">
                    <a-select size="large" style="width: 100%; height: 40px" :disabled="!dataCBT.id_mata_pelajaran"
                      v-model="dataCBT.tipe" @change="handleSelectType">
                      <a-select-option value="PH">PH</a-select-option>
                      <a-select-option value="PTS">PTS</a-select-option>
                      <a-select-option value="PAS">PAS</a-select-option>
                      <a-select-option value="Other">Other</a-select-option>
                      <a-select-option value="PPDB" v-if="this.dataCBT.id_mata_pelajaran === '16'">PPDB</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Duration (minutes)" required :colon="false">
                    <a-input-number :min="15" :max="180" step="15" size="large" :value="dataCBT.durasi"
                      @change="handleDuration" style="width: 100%" />
                  </a-form-item>
                </a-col>
                <a-col v-if="dataCBT.tipe === 'Other'" :span="24">
                  <a-form-item label="Name of CBT" required :colon="false">
                    <a-input v-model="dataCBT.other_tipe" size="large" class="w-100" />
                  </a-form-item>
                </a-col>
                <a-col v-if="dataCBT.tipe === 'PH'" :span="24">
                  <a-form-item label="Basic Competence" required :colon="false">
                    <a-select size="large" style="width: 100%; height: 40px" v-model="dataCBT.id_kd">
                      <a-select-option v-for="item in kdLists" :key="item.id" :value="item.id">Level {{ item.level ?
                        item.level.nama : "-" }} | (3.{{
    item.no || ""
  }}) {{ item.nama_pengetahuan || "" }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <template v-if="dataCBT.tipe !== 'PPDB' && dataCBT.tipe">
                  <div v-if="dataCBT.tipe === 'PH' || dataCBT.tipe === 'Other'">
                    <!-- <div v-if="true"> -->
                    <a-col :span="24">
                      <a-form-item label="Class to Assign" required :colon="false">
                        <a-select size="large" class="w-100" mode="multiple" v-model="dataCBT.raw_kelas_terpilih"
                          @change="handleChangeClassSelected">
                          <a-select-option v-for="kelas in classes" :key="kelas.id" :value="kelas.id">
                            {{ kelas.level ? kelas.level.nama : "" }}-{{
                              kelas.simbol
                            }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </div>
                  <div v-else>
                    <a-col :span="24">
                      <a-form-item label="Level to Assign" required :colon="false">
                        <a-select size="large" style="width: 100%; height: 40px" v-model="dataCBT.tingkat"
                          @change="handleChangeLevelSelected">
                          <template v-for="level in levels">
                            <a-select-option v-if="level.kelas.length" :key="level.id" :value="level.id">{{ level.nama
                            }}</a-select-option>
                          </template>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </div>
                  <template v-if="dataCBT.kelas_terpilih.length">
                    <a-col v-for="item in dataCBT.kelas_terpilih" :key="item.id_kelas" :span="24">
                      <!-- <a-form-item
                        :label="`${item.kelas} Supervisor`"
                        required
                        :colon="false"
                      > -->
                      <a-form-item v-for="(guruData, i) in item.id_guru" :key="`${item.id_kelas}-${i}`"
                        :label="`${item.kelas} Supervisor ${i + 1}`" required :colon="false">
                        <div class="d-flex">
                          <a-select show-search option-filter-prop="children" style="width: 100%; height: 40px"
                            :filter-option="filterOption" v-model="guruData.id" @change="e =>
                              handleSelectPengawas(
                                e,
                                item.id_kelas,
                                'id_guru',
                                i
                              )
                              " size="large">
                            <a-select-option v-for="item in pengawas" :key="item.id" :value="item.id">{{ item.nama
                            }}</a-select-option>
                          </a-select>
                          <a-button v-if="i === item.id_guru.length - 1 && i !== 0" type="danger"
                            @click.prevent="removeSupervisor(item.id_kelas)" size="large" class="ml-3"><a-icon
                              type="delete" /></a-button>
                        </div>
                      </a-form-item>
                      <a-button @click.prevent="addSupervisor(item.id_kelas)" class="w-100 mb-3" size="large"><a-icon
                          type="plus" />Add New {{ item.kelas }} Supervisor</a-button>
                      <!-- <template
                        v-if="dataCBT.tipe === 'PH' || dataCBT.tipe === 'Other'"
                      > -->
                      <template>
                        <a-form-item :label="`${item.kelas} Start At`" required :colon="false">
                          <a-date-picker v-model="item.waktu_ujian_dibuka" show-time format="YYYY-MM-DD HH:mm:ss" @change="(dateMoment, dateString) =>
                            handleSelectPengawas(
                              dateString,
                              item.id_kelas,
                              'waktu_ujian_dibuka'
                            )
                            " style="width: 100%" size="large" placeholder="" />
                        </a-form-item>
                        <a-form-item :label="`${item.kelas} End At`" required :colon="false">
                          <a-date-picker v-model="item.waktu_ujian_ditutup" show-time format="YYYY-MM-DD HH:mm:ss"
                            @change="(dateMoment, dateString) =>
                              handleSelectPengawas(
                                dateString,
                                item.id_kelas,
                                'waktu_ujian_ditutup'
                              )
                              " style="width: 100%" size="large" placeholder="" />
                        </a-form-item>
                      </template>
                    </a-col>
                  </template>
                </template>

                <template v-if="dataCBT.id_mata_pelajaran === '16'">
                  <a-col :span="24">
                    <a-form-item label="Gelombang" required :colon="false">
                      <a-select size="large" style="width: 100%; height: 40px" v-model="dataCBT.id_gelombang">
                        <a-select-option v-for="item in gelombang" :key="item.id" :value="item.id">{{
                          item.name }} ( {{ formatDate(item.dateStart) }} - {{ formatDate(item.dateEnd) }}
                          )</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item v-for="(guruData, i) in dataCBT.supervisorPpdb" :label="`PPDB Supervisor ${i + 1}`"
                      required :colon="false" :key="i">
                      <div class="d-flex">
                        <a-select style="width: 100%; height: 40px" size="large" @change="handlePPDB"
                          v-model="guruData.id">
                          <a-select-option v-for="item in pengawas" :key="item.id" :value="item.id">{{ item.nama
                          }}</a-select-option>
                        </a-select>
                        <a-button v-if="i !== 0 && i === dataCBT.supervisorPpdb.length - 1" type="danger" size="large"
                          class="ml-3" @click.prevent="removePpdbSupervisor"><a-icon type="delete" /></a-button>
                      </div>
                    </a-form-item>
                    <a-button @click.prevent="addPpdbSupervisor" class="w-100 mb-3" size="large"><a-icon type="plus" />Add
                      New PPDB Supervisor</a-button>
                    <template>
                      <a-form-item :label="`PPDB Start At`" required :colon="false">
                        <a-date-picker v-model="dataCBT.waktu_ppdb_dibuka" show-time format="YYYY-MM-DD HH:mm:ss"
                          style="width: 100%" size="large" placeholder="" />
                      </a-form-item>
                      <a-form-item :label="`PPDB End At`" required :colon="false">
                        <a-date-picker v-model="dataCBT.waktu_ppdb_ditutup" show-time format="YYYY-MM-DD HH:mm:ss"
                          style="width: 100%" size="large" placeholder="" />
                      </a-form-item>
                    </template>
                  </a-col>
                </template>
                <!-- <template
                  v-if="
                    dataCBT.tipe &&
                    dataCBT.tipe !== 'PH' &&
                    dataCBT.tipe !== 'Other'
                  "
                >
                  <a-col :span="24">
                    <a-form-item label="Start at" required :colon="false">
                      <a-date-picker
                        v-model="dataCBT.waktu_ujian_dibuka"
                        :disabled-date="disabledStartDate"
                        show-time
                        format="YYYY-MM-DD HH:mm:ss"
                        @openChange="handleStartOpenChange"
                        style="width: 100%"
                        size="large"
                        placeholder=""
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="24">
                    <a-form-item label="End at" required :colon="false">
                      <a-date-picker
                        v-model="dataCBT.waktu_ujian_ditutup"
                        :disabled-date="disabledEndDate"
                        show-time
                        format="YYYY-MM-DD HH:mm:ss"
                        :open="endOpen"
                        @openChange="handleEndOpenChange"
                        style="width: 100%"
                        size="large"
                        placeholder=""
                      />
                    </a-form-item>
                  </a-col>
                </template> -->
                <a-col :span="24">
                  <a-form-item label="Intructions" required :colon="false">
                    <ckeditor v-model="dataCBT.instruksi" :editor="editor" :config="editorConfig"></ckeditor>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <div class="p-3 border rounded mb-4">
                    <a-checkbox v-model="dataCBT.isBankSoal">
                      Set as New Question Bank
                    </a-checkbox>
                    <template v-if="dataCBT.isBankSoal">
                      <a-input size="large" class="my-3" v-model="dataCBT.nama_bank_soal"
                        placeholder="Set question template name..." />
                      <a-select size="large" style="width: 100%" v-model="dataCBT.id_level"
                        placeholder="Set question template level...">
                        <template v-for="level in levels">
                          <a-select-option v-if="level.kelas.length" :key="level.id" :value="level.id">{{ level.nama
                          }}</a-select-option>
                        </template>
                      </a-select>
                    </template>
                  </div>
                </a-col>
                <a-button :loading="loadingAdd" type="primary" size="large" block @click.prevent="generateCBT"
                  :disabled="isDisabledGenerate">Generate Now</a-button>
              </a-row>
            </div>
          </div>
        </a-col>
      </a-row>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import XLSX from 'xlsx'
import VueSticky from 'vue-sticky'
import ListSoalCbt from '@/components/app/CbtGuru/ListSoalCbt'
// const EmptyKartuSoal = () => import('@/components/app/CbtGuru/EmptyKartuSoal')
// const Card = () => import('@/components/app/Card')
// const KartuSoalGuide = () => import('@/components/app/CbtGuru/KartuSoalGuide')
const LoadingState = () => import('@/components/app/LoadingState')
const CounterCbt = () => import('@/components/app/CbtGuru/CounterCbt')
// const ListSoalCbt = () => import('@/components/app/CbtGuru/ListSoalCbt')
const EditSoalCbt = () => import('@/components/app/CbtGuru/EditSoalCbt')
const QuestionBankList = () => import('./QuestionBankList')
// const CbtForm = () => import('@/components/app/CbtGuru/CbtForm')
const types = ['PH', 'PTS', 'PAS', 'PAT']
const intruksi = `
  <p><strong>PETUNJUK UMUM&nbsp;</strong></p><p>1. Dahulukan menjawab soal-soal yang lebih mudah.&nbsp;</p><p>2. Periksa dan bacalah soal-soal dengan teliti sebelum menjawabnya.&nbsp;</p><p>3. Laporkan kepada pengawas ruang ujian, apabila terdapat soal/jawaban rusak, tidak lengkap dan tidak jelas.&nbsp;</p><p>4. Waktu yang disediakan untuk mengerjakan semua jawaban sesuai dengan jadwal yang ditentukan, apabila terlambat tidak diberikan penambahan waktu.&nbsp;</p><p>5. Tidak diizinkan menggunakan kalkulatorm HP, Tabel matematika atau alat bantu lainnya.&nbsp;</p><p>6. Periksa kembali pekerjaannya, sebelum disubmit kepada sistem.&nbsp;</p><p>7. Selama ujian berlangsung, peserta ujian harus mematuhi tata-tertib yang berlaku, bagi yang melanggarnya akan dikenakan sanksi.&nbsp;</p><p>8.<i><strong> <u>Mulailah mengerjakan soal dengan membaca lafadz basmalah dan shalawat, dan akhiri dengan hamdalah</u></strong></i></p>
`
export default {
  components: {
    // EmptyKartuSoal,
    // Card,
    // KartuSoalGuide,
    ListSoalCbt,
    CounterCbt,
    EditSoalCbt,
    LoadingState,
    QuestionBankList,
    // CbtForm,
  },
  data() {
    return {
      top: 10,
      loadingUpload: false,
      isLoadingQuestions: false,
      editable: false,
      loadingEditQuestion: false,
      newComponent: false,
      levels: [],
      classes: [],
      filterLevels: [],
      mapels: [],
      types,
      pengawas: [],
      kdLists: [],
      selectedPengawas: {
        pengawas1: '',
        pengawas2: '',
        pengawas3: '',
        pengawas4: '',
      },
      newData: {
        id_kelas: '',
      },
      excelData: [],
      excelNewData: [],
      dataCBT: {
        id_kd: null,
        id_mata_pelajaran: null,
        durasi: 15,
        tingkat: '',
        raw_kelas_terpilih: [],
        kelas_terpilih: [],
        tipe: this.tipeCbt,
        kkm: 0,
        waktu_ujian_dibuka: null,
        waktu_ujian_ditutup: null,
        instruksi: intruksi,
        tipe_cbt: '',
        other_tipe: null,
        isBankSoal: false,
        nama_bank_soal: null,
        id_bank_soal: null,
        supervisorPpdb: [],
        waktu_ppdb_dibuka: null,
        waktu_ppdb_ditutup: null,
        id_gelombang: '',
      },
      counterCBT: {
        counterQuestions: 0,
        counterEssay: 0,
        counterMultipleChoice: 0,
        counterEmpty: 0,
        counterBobot: 0,
      },
      endOpen: false,
      loadingAdd: false,
      visibleModalQuestionCard: false,
      visibleModalQuestionBank: false,
      isFetching: false,
      reloadCount: 0,
      gelombang: [],
    }
  },
  methods: {
    // handleMapelPpdb(value) { },
    async chooseQuestionBank(data) {
      try {
        const payload = await this.$store.dispatch('bankSoal/FETCH_QUESTION_BANK_BY_ID', { id: data.id })
        this.excelData = payload.paket_soals.map(soal => {
          return {
            ...soal,
            bobot_soal: Number(soal.bobot),
            tipe_soal: soal.tipe,
            jawaban: soal.jawaban ? JSON.parse(soal.jawaban) : null,
          }
        })
        this.visibleModalQuestionBank = false
        this.dataCBT.id_bank_soal = data.id
        this.$notification.success({
          message: 'SUCCESS IMPORT QUESTION BANK',
        })
      } catch (err) {
        console.log(err)
      }
    },
    addPpdbSupervisor() {
      this.dataCBT.supervisorPpdb.push({ id: null })
    },
    handlePPDB(e) {
      console.log(this.dataCBT.supervisorPpdb)
      // console.log(e)
    },
    addSupervisor(idKelas) {
      console.log(this.dataCBT.kelas_terpilih)
      const data = this.dataCBT.kelas_terpilih.find(kelas => kelas.id_kelas === idKelas)
      if (data) data.id_guru.push({ id: null })
    },
    removePpdbSupervisor() {
      this.dataCBT.supervisorPpdb.pop()
    },
    handleToggleModalQuestionCard() {
      this.visibleModalQuestionCard = !this.visibleModalQuestionCard
    },
    handleToggleModalQuestionBank() {
      this.visibleModalQuestionBank = !this.visibleModalQuestionBank
    },
    className(id) {
      let result = null
      for (let i = 0; i < this.levels.length; i++) {
        const element = this.levels[i]
        const target = element.kelas.find(kelas => kelas.id === id)
        if (target) {
          result = element.nama + '-' + target.simbol
          break
        }
      }
      return result
    },
    handleChangeLevelSelected(value) {
      this.dataCBT.tingkat = value
      // console.log(this.levels, value)
      const target = this.levels.find(level => level.id === value)
      if (target) {
        // console.log(this.levels)
        this.dataCBT.kelas_terpilih = target.kelas.map(el => {
          return {
            id_kelas: el.id,
            kelas: this.className(el.id),
            // id_guru: null,
            waktu_ujian_dibuka: null,
            waktu_ujian_ditutup: null,
            id_guru: [{ id: null }],
          }
        })
      }
    },
    handleSelectType(value) {
      this.dataCBT.tipe = value
      this.dataCBT.kelas_terpilih = []
      this.dataCBT.raw_kelas_terpilih = []
      this.dataCBT.tingkat = null
      this.dataCBT.id_kd = null
    },
    handleChangeClassSelected(value) {
      const idGuru = this.dataCBT.tipe === 'PH' ? this.user.id : null
      this.dataCBT.kelas_terpilih = value.map(id => {
        return {
          id_kelas: id,
          kelas: this.className(id),
          // id_guru: idGuru,
          id_guru: [{ id: idGuru }],
          waktu_ujian_dibuka: null,
          waktu_ujian_ditutup: null,
        }
      })
      console.log(this.dataCBT.kelas_terpilih)
    },
    cancelEdit() {
      this.handleToggleModalQuestionCard()
      this.editable = false
    },
    saveEdit({ questions }) {
      this.editable = false
      this.excelData = questions
      this.$notification.success({
        message: 'Success.',
        description: 'Your Kartu Soal has been updated',
      })
      this.handleToggleModalQuestionCard()
      this.resetCounter()
      this.startCounter()
      this.dataCBT.tipe_cbt = this.tipeCbt
      this.reloadCount++
    },
    resetCounter() {
      this.counterCBT.counterQuestions = 0
      this.counterCBT.counterEssay = 0
      this.counterCBT.counterMultipleChoice = 0
      this.counterCBT.counterBobot = 0
      this.counterCBT.counterEmpty = 0
    },
    startCounter() {
      this.counterCBT.counterQuestions = this.excelData.length
      this.excelData.forEach(row => {
        this.counterCBT.counterBobot += parseFloat(row.bobot_soal)
        if (row.tipe_soal === 'Multiple Choice') {
          this.counterCBT.counterMultipleChoice++
        } else if (row.tipe_soal === 'Essay') {
          this.counterCBT.counterEssay++
        } else {
          this.counterCBT.counterEmpty++
        }
      })
    },
    numberQuestion(index) {
      return ++index
    },
    // fetchDataMapel() {
    //   this.$store.dispatch('cbt/FETCH_MAPEL_BY_ID_TEACHER', { idGuru: this.user.id })
    //     .then(data => {
    //       // console.log(data)
    //       this.mapels = data.mapel
    //       // this.levels = data.tingkat
    //     })
    // },
    async fetchDataKd() {
      try {
        const data = await this.$store.dispatch('cbt/FETCH_KD_BY_ID_MAPEL', { idMapel: this.dataCBT.id_mata_pelajaran })
        // console.log(data)
        this.kdLists = data
      } catch (err) {
        console.log(err)
      }
    },
    async fetchDataMapel() {
      try {
        const data = await this.$store.dispatch('cbt/FETCH_LIST_KELAS_GURU_PENGAMPU')
        this.mapels = data
      } catch (err) {
        console.log(err)
      }
    },
    fetchDataLevelClass() {
      this.$store.dispatch('master/FETCH_LEVELS_AND_CLASSES', { idMapel: this.dataCBT.id_mata_pelajaran })
        .then(data => {
          // console.log(data)
          this.levels = data
          // console.log(this.levels)
        })
    },
    fetchDataPengawas() {
      this.$store.dispatch('cbt/FETCH_PENGAWAS')
        .then(data => {
          this.pengawas = data
        })
    },
    handleSelectMapel(value) {
      this.dataCBT.tipe = ''
      if (value === '16') {
        this.dataCBT.id_mata_pelajaran = value
        this.dataCBT.tipe = 'PPDB'
        this.dataCBT.supervisorPpdb.push({ id: this.user.id })
      } else {
        this.dataCBT.id_mata_pelajaran = value

        this.fetchDataKd()
        this.fetchDataLevelClass()
        // console.log(this.dataCBT.id_mata_pelajaran)
        const target = this.mapels.find(el => el.id === this.dataCBT.id_mata_pelajaran)
        // console.log(target)
        if (target) {
          // console.log(target)
          this.classes = target.kelas
        }
      }
    },
    handleDuration(value) {
      this.dataCBT.durasi = value
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSelectPengawas(value, idKelas, column, index = null) {
      const target = this.dataCBT.kelas_terpilih.find(kelas => kelas.id_kelas === idKelas)
      if (target) {
        if (column === 'id_guru' && index !== null) target.id_guru[index].id = value
        // else target[column] = value
        // console.log(this.dataCBT.kelas_terpilih)
      }
    },
    disabledStartDate(startValue) {
      const endValue = this.dataCBT.waktu_ujian_ditutup
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.dataCBT.waktu_ujian_dibuka
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    excelExport(event) {
      this.loadingUpload = true
      if (event.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const reader = new FileReader()
        reader.onload = () => {
          const fileData = reader.result
          const wb = XLSX.read(fileData, { type: 'binary' })
          if (wb.Sheets.Penyusunan) {
            const sheets = XLSX.utils.sheet_to_json(wb.Sheets.Penyusunan)
            const durasi = parseInt(sheets[4].__EMPTY_7.replace(': ', ''))
            const totalSoal = parseInt(sheets[5].__EMPTY_7)
            const kkm = parseInt(sheets[5].__EMPTY)
            const startSoalIndex = 8
            let counterQuestions = 0
            let counterEssay = 0
            let counterMultipleChoice = 0
            let counterEmpty = 0
            let counterBobot = 0
            for (let i = startSoalIndex; i < startSoalIndex + totalSoal; i++) {
              counterQuestions++
              const noSoal = sheets[i].__EMPTY_4
              const sk = sheets[i].__EMPTY ? sheets[i].__EMPTY : ''
              const indikator = sheets[i].__EMPTY_1 ? sheets[i].__EMPTY_1 : ''
              const soal = sheets[i].__EMPTY_3 ? sheets[i].__EMPTY_3 : ''
              let jawaban = sheets[i].__EMPTY_5 ? sheets[i].__EMPTY_5 : ''
              const kunciJawaban = sheets[i].__EMPTY_6 ? sheets[i].__EMPTY_6.toLowerCase() : ''
              const bobotSoal = sheets[i].__EMPTY_7 ? sheets[i].__EMPTY_7 : ''
              const kriteria = sheets[i].__EMPTY_8 ? sheets[i].__EMPTY_8 : ''
              const aspek = ''
              let tipeSoal
              counterBobot += bobotSoal
              if (soal && jawaban === '') {
                tipeSoal = 'Essay'
                counterEssay++
              } else if (soal && jawaban && kunciJawaban) {
                tipeSoal = 'Multiple Choice'
                counterMultipleChoice++
                const newLine = jawaban.replace(/\n/g, 'BARIS_BARU ')
                jawaban = newLine.split('BARIS_BARU ')
              } else {
                tipeSoal = 'Empty'
                counterEmpty++
              }
              const soalObj = {
                no_soal: noSoal,
                tipe_soal: tipeSoal,
                standar_kompetensi: sk,
                indikator,
                soal,
                jawaban,
                kunci_jawaban: kunciJawaban,
                bobot_soal: bobotSoal,
                kriteria,
                aspek,
                kkm,
              }
              this.excelData.push(soalObj)
              this.dataCBT.kkm = kkm
              this.dataCBT.durasi = durasi
            }
            // console.log(this.excelData)
            const counter = { counterQuestions, counterEmpty, counterEssay, counterMultipleChoice, counterBobot }
            this.counterCBT = counter
          } else {
            this.$notification.error({
              message: 'Sorry.',
              description: 'This document is not a Kartu Soal',
            })
          }
        }
        reader.readAsBinaryString(event)
      } else {
        this.$notification.error({
          message: 'Sorry.',
          description: 'XLSX are allowed',
        })
      }
      this.loadingUpload = false
      return false
    },
    async generateCBT() {
      try {
        if (this.counter.counterEmpty > 0) {
          return this.$notification.error({
            message: 'Sorry.',
            description: `There are ${this.counter.counterEmpty} questions that are still empty, Please recheck the Kartu Soal document`,
          })
        } else if (this.counter.counterBobot !== 100) {
          return this.$notification.error({
            message: 'Sorry.',
            description: `Total Bobot Soal is ${this.counter.counterBobot}, Make sure total Bobot Soal is 100.`,
          })
        } else if (this.editable) {
          return this.$notification.error({
            message: 'Sorry.',
            description: 'You still editing the Kartu Soal, Make sure you have saved any changes.',
          })
        } else if (this.dataCBT.isBankSoal && (!this.dataCBT.nama_bank_soal || !this.dataCBT.id_level)) {
          return this.$notification.error({
            message: 'Sorry.',
            description: 'Please add your question template name & level if you want to set this CBT as new question template.',
          })
        }

        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure want to generate CBT ?</div>
          ),
          onOk: async () => {
            try {
              this.loadingAdd = true
              this.dataCBT.tipe_cbt = this.tipeCbt
              this.dataCBT.id_level = this.dataCBT.tingkat
              this.dataCBT.waktu_ujian_dibuka = this.dataCBT.waktu_ujian_dibuka ? moment(this.dataCBT.waktu_ujian_dibuka).format('YYYY-MM-DD HH:mm:ss') : null
              this.dataCBT.waktu_ujian_ditutup = this.dataCBT.waktu_ujian_ditutup ? moment(this.dataCBT.waktu_ujian_ditutup).format('YYYY-MM-DD HH:mm:ss') : null
              this.dataCBT.waktu_ppdb_dibuka = moment(this.dataCBT.waktu_ppdb_dibuka).format('YYYY-MM-DD HH:mm:ss')
              this.dataCBT.waktu_ppdb_ditutup = moment(this.dataCBT.waktu_ppdb_ditutup).format('YYYY-MM-DD HH:mm:ss')
              if (this.dataCBT.tipe === 'Other') this.dataCBT.tipe = this.dataCBT.other_tipe
              const dataCBT = this.dataCBT
              const pengawas = this.dataCBT.kelas_terpilih
              // console.log(this.dataCBT.kelas_terpilih)
              // console.log(pengawas)
              const cbtSoalBundle = this.excelData.map(row => {
                return {
                  nomor_soal: row.no_soal,
                  soal: row.soal,
                  jawaban: row.tipe_soal === 'Essay' ? null : JSON.stringify(row.jawaban),
                  kriteria: row.kriteria,
                  aspek: row.aspek,
                  tipe_soal: row.tipe_soal,
                  kunci_jawaban: row.kunci_jawaban,
                  bobot_soal: row.bobot_soal,
                  indikator: row.indikator,
                  standar_kompetensi: row.standar_kompetensi,
                }
              })
              // console.log(cbtSoalBundle)
              await this.$store.dispatch('cbt/POST_CBT', {
                idGuru: this.user.id,
                dataCBT,
                cbtSoalBundle,
                pengawas,
              })
              this.dataCBT = {}
              this.excelData = []
              this.$notification.success({
                message: 'Success.',
                description: 'CBT has been generated',
              })
              this.$router.push({ name: 'Cbt Teacher' })
              this.$store.commit('menu/SET_STATE', {
                currentMenu: ['Cbt Teacher'],
              })
            } catch (err) {
              console.log(err)
              this.$notification.error({
                message: 'Error',
                description: 'CBT has not been generated. Please try again later or contact admin.',
              })
            } finally {
              this.loadingAdd = false
            }
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Generate',
        })
      } catch (err) {
        console.log(err)
      }
    },
    async fetchExistingCbt(id) {
      try {
        this.isLoadingQuestions = true
        const idCbt = id
        const idGuru = this.user.id
        const { cbt } = await this.$store.dispatch('cbt/FETCH_CBT_BY_ID', { idCbt, idGuru })
        if (cbt.cbt_soals.length) {
          this.excelData = cbt.cbt_soals.map(soal => {
            return {
              ...soal,
              id: null,
              id_cbt: null,
              jawaban: soal.tipe_soal === 'Multiple Choice' ? JSON.parse(soal.jawaban) : soal.jawaban,
            }
          })
          this.startCounter()
        }
      } catch (error) {
        console.log('error fetching existing cbt', error)
        this.$notification.error({
          message: 'Error',
          description: 'The process of fetching existing questions encountered problems. Please try again later',
        })
      } finally {
        this.isLoadingQuestions = false
      }
    },
    async getGelombang() {
      const data = await this.$store.dispatch('ppdb/GET_PPDB_GELOMBANG')
      this.gelombang = data.gelombang
    },
    formatDate(date) {
      return moment(date).locale('id').format('LL')
    },
  },
  computed: {
    user() { return this.$store.state.user.user },
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      return this.$store.state.ckEditor.editorConfig
    },
    filteredPengawas() {
      if (this.pengawas.length) {
        // return this.pengawas.filter(item => !this.dataCBT.kelas_terpilih.find(kelas => kelas.id_guru === item.id))
        return this.pengawas.filter(item => !this.dataCBT.kelas_terpilih.find(kelas => kelas.id_guru.find(dat => dat.id === item.id)))
      }

      return []
    },
    tipeCbt() {
      const multipleChoice = this.counter.counterMultipleChoice
      const essay = this.counter.counterEssay
      let tipeCbt = ''
      if (multipleChoice > 0 && essay > 0) {
        tipeCbt = 'Multiple Choice & Essay'
      } else if (multipleChoice > 0 && essay < 1) {
        tipeCbt = 'Multiple Choice'
      } else if (multipleChoice < 1 && essay > 0) {
        tipeCbt = 'Essay'
      } else {
        tipeCbt = 'Unknown'
      }
      return tipeCbt
    },
    isDisabledGenerate() {
      if (this.dataCBT.tipe === 'PPDB') {
        const isFilledForm = Boolean(this.dataCBT.id_mata_pelajaran && this.dataCBT.tipe && this.dataCBT.durasi && this.dataCBT.instruksi && this.excelData?.length)
        if (isFilledForm) {
          return false
        } else {
          return true
        }
      } else {
        const isFilledForm = Boolean(this.dataCBT.id_mata_pelajaran && this.dataCBT.tipe && this.dataCBT.durasi && this.dataCBT.kelas_terpilih?.length && this.dataCBT.instruksi)

        let isFilledKd = true
        if (this.dataCBT.tipe === 'PH') {
          isFilledKd = false
          if (this.dataCBT.id_kd) isFilledKd = true
        }

        let isFilledOtherType = true
        if (this.dataCBT.tipe === 'Other') {
          isFilledOtherType = false
          if (this.dataCBT.other_tipe) isFilledOtherType = true
        }

        let isFilledPengawas = false
        let isFilledWaktuUjian = false
        if (this.dataCBT.kelas_terpilih?.length) {
          // isFilledPengawas = Boolean(this.dataCBT.kelas_terpilih.filter((kelas) => kelas.id_guru).length === this.dataCBT.kelas_terpilih.length)
          isFilledPengawas = Boolean(this.dataCBT.kelas_terpilih.find((kelas) => kelas.id_guru.find(dat => dat.id === null))) === false
          // console.log(isFilledPengawas)

          // if (this.dataCBT.tipe === 'PH' || this.dataCBT.tipe === 'Other') {
          //   isFilledWaktuUjian = Boolean(this.dataCBT.kelas_terpilih.filter((kelas) => kelas.waktu_ujian_dibuka && kelas.waktu_ujian_ditutup).length === this.dataCBT.kelas_terpilih.length)
          // } else {
          //   isFilledWaktuUjian = Boolean(this.dataCBT.waktu_ujian_dibuka && this.dataCBT.waktu_ujian_ditutup)
          // }
          isFilledWaktuUjian = Boolean(this.dataCBT.kelas_terpilih.filter((kelas) => kelas.waktu_ujian_dibuka && kelas.waktu_ujian_ditutup).length === this.dataCBT.kelas_terpilih.length)
        }

        if (isFilledForm && isFilledKd && isFilledOtherType && isFilledPengawas && isFilledWaktuUjian && this.excelData?.length) {
          return false
        } else {
          return true
        }
      }
    },
    counter() {
      const multipleChoice = this.excelData.filter(dat => dat.tipe_soal === 'Multiple Choice').length
      const essay = this.excelData.filter(dat => dat.tipe_soal === 'Essay').length
      const empty = this.excelData.length - multipleChoice - essay
      const bobot = this.excelData.map(d => Number(d.bobot_soal)).reduce((p, c) => p + c)
      return {
        counterQuestions: this.excelData.length,
        counterEmpty: empty,
        counterMultipleChoice: multipleChoice,
        counterEssay: essay,
        counterBobot: bobot,
      }
    },
  },
  mounted() {
    if (this.$route.query.duplicate) {
      const id = this.$route.query.duplicate
      // console.log(id)
      this.fetchExistingCbt(id)
    }
    this.fetchDataMapel()
    this.fetchDataPengawas()
    this.fetchDataLevelClass()
    this.getGelombang()
  },
  directives: {
    sticky: VueSticky,
  },
}
</script>

<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 15px;
}
</style>
